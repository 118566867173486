import React from "react";

import TarangLogo from "../../assets/images/tarang_logo.png";

import "./footer-styles.css";

const Footer = () => {
  return (
    <footer className="footer">
      <span style={{ color: "black", fontSize: 18, fontWeight: 500 }}>
        POC developed by{" "}
      </span>
      <img src={TarangLogo} alt="Tarang_logo" className="logo" />
    </footer>
  );
};

export default Footer;
