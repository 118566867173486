import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Paper from "@mui/material/Paper";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

import Dirham from "../../assets/images/dirham.png";

import "./card-styles.css";

const DetailCard = (props) => {
  const { bank, date, amount } = props;
  return (
    <Grid className="card-grid" item xs={12} sm={6} md={4} lg={4} xl={4}>
      <Paper
        sx={{ borderRadius: 3, paddingLeft: 1, paddingRight: 1 }}
        elevation={2}
      >
        <div className="card-container">
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="date-container"
          >
            <AccountBalanceRoundedIcon sx={{ width: 20, height: 20 }} />
            <Typography sx={{ fontSize: 14, marginLeft: 2 }} gutterBottom>
              {bank}
            </Typography>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="date-container"
          >
            <CalendarMonthRoundedIcon sx={{ width: 20, height: 20 }} />
            <Typography sx={{ fontSize: 14, marginLeft: 2 }} gutterBottom>
              {date}
            </Typography>
          </div>
          {/* <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography> */}
          {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography> */}
          <div
            className="amount-container"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <img alt="dirham" src={Dirham} className="image" />
            <div style={{ flexDirection: "column" }}>
              <Typography variant="body2" sx={{ marginLeft: 2 }}>
                Salary Amount
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                  fontSize: 20,
                  lineHeight: 2,
                  fontWeight: 600,
                }}
              >
                {Number(amount).toFixed(3)}
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default DetailCard;
