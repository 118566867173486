import axios from "axios";

const complianceChecker = async (
  complianceFiles,
  recordFiles,
  promptInput,
  key
) => {
  let url = "";
  if (key === "rfp") {
    url = `https://tarangtech.in/get_controller_rfp?prompt=${promptInput}`;
  } else if (key === "recruitment") {
  
    url = `https://tarangtech.in/get_controller_recruitment?prompt=${promptInput}`;
  } else if (key === "healthcare") {
    url = `https://tarangtech.in/get_controller_health_check?prompt=${promptInput}`;
  } else if (key === "financial") {
    url = `https://tarangtech.in/get_controller_financial_report?prompt=${promptInput}`;
  } else if (key === "marketing") {
    url = `https://tarangtech.in/get_controller_marketing_report?prompt=${promptInput}`;
  } else if (key === "dataCenter") {
    url = `https://tarangtech.in/get_controller_technical_eval_report?prompt=${promptInput}`;
  } else if (key === "governmentPolicy") {
    url = `https://tarangtech.in/get_controller_govt_policy_report?prompt=${promptInput}`;
  }

  const formData = new FormData();
  formData.append("files", complianceFiles[0]);
  for (let i = 0; i < recordFiles.length; i++) {
    formData.append("files", recordFiles[i]);
  }
  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response?.data?.data) {
      return response?.data?.data;
    } else {
      return "";
    }
  } catch (err) {
    console.log("API Error", err);
    return "";
  }
};

export { complianceChecker };
