import { PDFDocument } from "pdf-lib";

export async function splitPdfFile(file, pagesPerChunk) {
  const fileBuffer = await file.arrayBuffer();
  const inputPdf = await PDFDocument.load(fileBuffer);
  const pageCount = inputPdf.getPageCount();
  const chunks = [];

  for (let startPage = 0; startPage < pageCount; startPage += pagesPerChunk) {
    const endPage = Math.min(
      startPage === 0 ? 11 : startPage + pagesPerChunk - 1,
      pageCount
    );
    const newPdf = await PDFDocument.create();

    for (let i = startPage; i < endPage; i++) {
      const [copiedPage] = await newPdf.copyPages(inputPdf, [i]);
      newPdf.addPage(copiedPage);
    }

    const newPdfBytes = await newPdf.save();
    if (newPdfBytes) {
      chunks.push(new Blob([newPdfBytes], { type: "application/pdf" }));
    } else {
      break;
    }
  }

  return chunks;
}
