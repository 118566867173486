import moment from "moment";
import axios from "axios";

import { BASE_URL } from "../constants";

const formatDate = (inputDate) => {
  const parsedDate = moment(
    inputDate,
    [
      "DDMMMYY",
      "DD-MMM-YYYY",
      "DD-MM-YYYY",
      "DD MMM YYYY",
      "DD/MM/YYYY",
      "DD MMMM YYYY",
      "DDMMMYYYY",
    ],
    true
  );
  return parsedDate.format("DD-MMM-YYYY");
};

const getSalaryDetails = async (files, isMultiple = false, chunks = []) => {
  if (chunks.length) {
    let finalRes = [];
    const fileName = files[0].name.split(".")[0];
    for (let i = 0; i < chunks.length; i++) {
      let url = `${BASE_URL}pdf_scanner`;
      const formData = new FormData();
      await formData.append("file", chunks[i]);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 600000,
        };
        const response = await axios.post(url, formData, config);
        if (response.data.length) {
          let formattedData = response.data.filter((item) => {
            return Object.keys(item).length > 0;
          });
          formattedData = formattedData.map((item) => {
            return {
              ...item,
              date: item.date.replace(/\)/g, "J"),
            };
          });
          formattedData = formattedData.map((item) => {
            const { salary } = item;
            return {
              date: formatDate(item.date),
              salary: salary.includes(",") ? salary.replace(/,/g, "") : salary,
              bank: fileName,
            };
          });
          let flag = -1;
          for (let i = 0; i < formattedData.length - 1; i++) {
            for (let j = i + 1; j < formattedData.length; j++) {
              if (formattedData[i].date === formattedData[j].date) {
                flag = i;
              }
            }
          }
          if (flag !== -1) {
            formattedData.splice(flag, 1);
          }
          finalRes = [...finalRes, ...formattedData];
        } else {
        }
      } catch (err) {
        console.log("API Error", err);
        return "An error occurred due to either a large file size or a slow network response";
      }
    }
    return finalRes.length ? finalRes : [];
  } else {
    if (isMultiple) {
      let finalRes = [];
      for (let i = 0; i < files.length; i++) {
        let url = `${BASE_URL}pdf_scanner`;
        const fileName = files[i].name.split(".")[0];
        const formData = new FormData();
        await formData.append("file", files[i]);
        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 600000,
          };
          const response = await axios.post(url, formData, config);
          if (response.data.length) {
            let formattedData = response.data.filter((item) => {
              return Object.keys(item).length > 0;
            });
            formattedData = formattedData.map((item) => {
              return {
                ...item,
                date: item.date.replace(/\)/g, "J"),
              };
            });
            formattedData = formattedData.map((item) => {
              const { salary } = item;
              return {
                date: formatDate(item.date),
                salary: salary.includes(",")
                  ? salary.replace(/,/g, "")
                  : salary,
                bank: fileName,
              };
            });
            let flag = -1;
            for (let i = 0; i < formattedData.length - 1; i++) {
              for (let j = i + 1; j < formattedData.length; j++) {
                if (formattedData[i].date === formattedData[j].date) {
                  flag = i;
                }
              }
            }
            if (flag !== -1) {
              formattedData.splice(flag, 1);
            }
            finalRes = [...finalRes, ...formattedData];
          } else {
          }
        } catch (err) {
          console.log("API Error", err);
          return "An error occurred due to either a large file size or a slow network response";
        }
      }
      return finalRes.length ? finalRes : [];
    } else {
      let url = `${BASE_URL}pdf_scanner`;
      const fileName = files[0].name.split(".")[0];
      const formData = new FormData();
      await formData.append("file", files[0]);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 600000,
        };
        const response = await axios.post(url, formData, config);
        if (response.data.length) {
          let formattedData = response.data.filter((item) => {
            return Object.keys(item).length > 0;
          });
          formattedData = formattedData.map((item) => {
            return { ...item, date: item.date.replace(/\)/g, "J") };
          });
          formattedData = formattedData.map((item) => {
            const { salary } = item;
            return {
              date: formatDate(item.date),
              salary: salary.includes(",") ? salary.replace(/,/g, "") : salary,
              bank: fileName,
            };
          });
          let flag = -1;
          for (let i = 0; i < formattedData.length - 1; i++) {
            for (let j = i + 1; j < formattedData.length; j++) {
              if (formattedData[i].date === formattedData[j].date) {
                flag = i;
              }
            }
          }
          if (flag !== -1) {
            formattedData.splice(flag, 1);
          }
          return formattedData;
        } else {
          return [];
        }
      } catch (err) {
        console.log("API Error", err);
        return "An error occurred due to either a large file size or a slow network response";
      }
    }
  }
};

export { getSalaryDetails };
