import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { saveAs } from "file-saver";
import { TailSpin } from "react-loader-spinner";
import { SiMicrosoftexcel } from "react-icons/si";
import { TiHome } from "react-icons/ti";

import UploadFileImage from "../../assets/images/upload_file.png";
import PdfIcon from "../../assets/images/pdf_icon.png";
import NotAllowedIcon from "../../assets/images/not_allowed_icon.png";
import Footer from "../../components/footer/Footer";
import CustomInputBox from "../../components/custom-input-box/CustomInputBox";
import Timer from "../../components/timer/Timer";
import { complianceChecker } from "../../network/complianceChecker";

import "./rfpBot-styles.css";
var XLSX = require("xlsx");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RFPBot = () => {
  const navigation = useNavigate();
  const [invalidRFPFileError, setInvalidRFPFileError] = useState("");
  const [invalidProposalFileError, setInvalidProposalFileError] = useState("");
  const [uploadedRFPFileName, setUploadedRFPFileName] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [acceptedRFPFiles, setAcceptedRFPFiles] = useState([]);
  const [acceptedProposalFiles, setAcceptedProposalFiles] = useState([]);
  const [flag, setFlag] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    document.title = "Tarang Proposal Evaluator";
  }, []);

  const ACCEPTED_FILE_TYPES = { "application/pdf": [] };

  const onRFPFileDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    console.log("RFP accepted", acceptedFiles);
    console.log("RFP rejected", rejectedFiles);
    setUploadedRFPFileName("");
    setResult("");
    setFlag(false);
    setAcceptedRFPFiles(acceptedFiles?.length ? acceptedFiles : []);
    setInvalidRFPFileError(rejectedFiles?.length ? "File not supported" : "");
    if (acceptedFiles?.length) {
      setIsSnackbarOpen(true);
      setUploadedRFPFileName(
        acceptedFiles?.length ? acceptedFiles?.[0]?.name : ""
      );
    }
  }, []);

  const sendFilesForMatching = async (rpfFile, proposalFiles) => {
    setIsLoading(true);
    const response = await complianceChecker(
      rpfFile,
      proposalFiles,
      inputValue,
      "rfp"
    );
    setResult(response);
    setFlag(true);
    setIsLoading(false);
  };

  const handleSubmitBtnClick = async () => {
    await sendFilesForMatching(acceptedRFPFiles, acceptedProposalFiles);
  };

  const onProposalFileDrop = async (acceptedFiles, rejectedFiles) => {
    setSnackbarMsg("");
    console.log("ad image accepted", acceptedFiles);
    console.log("ad image rejected", rejectedFiles);
    setResult("");
    setFlag(false);
    if (acceptedProposalFiles?.length + acceptedFiles?.length > 4) {
      const tempFiles = [...acceptedProposalFiles, ...acceptedFiles];
      tempFiles.length = 4;
      setAcceptedProposalFiles(tempFiles);
      setSnackbarMsg("Maximum 4 files are allowed");
    } else {
      if (acceptedProposalFiles?.length) {
        setAcceptedProposalFiles([...acceptedProposalFiles, ...acceptedFiles]);
      } else {
        setAcceptedProposalFiles(acceptedFiles);
      }
      setInvalidProposalFileError(
        rejectedFiles?.length ? "File not supported" : ""
      );
    }
    if (acceptedFiles?.length) {
      setIsSnackbarOpen(true);
    }
  };

  const { getInputProps, getRootProps, isDragActive, isDragAccept } =
    useDropzone({
      multiple: false,
      onDrop: onRFPFileDrop,
      accept: ACCEPTED_FILE_TYPES,
    });

  const {
    getInputProps: proposalGetInputProps,
    getRootProps: proposalGetRootProps,
    isDragActive: proposalIsDragActive,
    isDragAccept: proposalIsDragAccept,
  } = useDropzone({
    multiple: true,
    onDrop: onProposalFileDrop,
    accept: ACCEPTED_FILE_TYPES,
  });

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const renderPdfFileNames = () => {
    return (
      <p className="message">
        <img
          src={PdfIcon}
          alt="pdf-icon"
          style={{ width: 24, height: 24, marginRight: 10 }}
        />
        &nbsp;
        {acceptedRFPFiles.length > 1
          ? `${acceptedRFPFiles.length} files uploaded`
          : acceptedRFPFiles[0].name.length > 20
          ? `${acceptedRFPFiles[0].name.slice(0, 20)}...`
          : `${acceptedRFPFiles[0].name}`}
      </p>
    );
  };

  // const handleDownload = () => {
  //   const data = [];

  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.aoa_to_sheet(data);

  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   const blob = XLSX.write(wb, {
  //     bookType: "xlsx",
  //     type: "array",
  //     mimeType:
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });

  //   saveAs("../../assets/report.xlsx", "report.xlsx");
  // };

  const handleDeselectFileClick = (fileIndex) => {
    const tempProposalFiles = [...acceptedProposalFiles];
    tempProposalFiles.splice(fileIndex, 1);
    setAcceptedProposalFiles(tempProposalFiles);
  };

  const renderProposalFileNames = () => {
    return acceptedProposalFiles.map((fileItem, index) => {
      const { name } = fileItem;
      const displayName = name.slice(0, -4);
      return (
        <Chip
          variant="filled"
          label={
            displayName?.length > 15
              ? `${displayName.slice(0, 15)}..`
              : displayName
          }
          onDelete={() => {
            handleDeselectFileClick(index);
          }}
          style={{ margin: 4 }}
          avatar={
            <img
              src={PdfIcon}
              alt="pdf-icon"
              style={{ width: 24, height: 24 }}
            />
          }
        />
      );
    });
  };

  const renderSubmitButton = () => {
    return (
      <div
        onClick={
          !!uploadedRFPFileName &&
          !!acceptedProposalFiles?.length &&
          inputValue &&
          !isLoading
            ? handleSubmitBtnClick
            : () => {}
        }
        style={{ cursor: "pointer" }}
        className="trail_btn"
      >
        <p
          style={{
            background:
              !!acceptedProposalFiles?.length &&
              !!uploadedRFPFileName &&
              !!inputValue
                ? ""
                : "gray",
            border:
              !!acceptedProposalFiles?.length &&
              !!uploadedRFPFileName &&
              !!inputValue
                ? "1px solid white"
                : "none",
          }}
        >
          Submit
        </p>
      </div>
    );
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMsg ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMsg ? snackbarMsg : "File uploaded successfully!"}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div className="rfp-bot-container">
      {renderSnackbar()}
      <div
        style={{
          position: "absolute",
          left: 10,
          top: 10,
          color: "white",
        }}
      >
        <TiHome
          className="home-icon"
          onClick={() => {
            navigation(-1);
          }}
          size={40}
          color="white"
        />
        <p style={{ fontSize: 12, textAlign: "center" }}>Home</p>
      </div>
      <div className="sub-container">
        <div className="inputs-section">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="dropzone-wrapper">
              <p className="upload-header-text">Please upload the RFP</p>
              <Paper
                style={{
                  borderRadius: 10,
                }}
                elevation={3}
              >
                <div
                  className={`dropzone-container ${
                    isDragActive && isDragAccept ? "active-dropzone" : ""
                  }`}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="dropzone-sub-container">
                    <img
                      src={UploadFileImage}
                      alt="upload-file"
                      style={{ width: 30, height: 30 }}
                    />
                    <em>(Only *.pdf files will be accepted)</em>
                    {!!invalidRFPFileError && (
                      <p className="error-text" style={{ marginBottom: 10 }}>
                        <img
                          src={NotAllowedIcon}
                          alt="not-allowed-icon"
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        &nbsp;
                        {invalidRFPFileError}
                      </p>
                    )}
                    {!!uploadedRFPFileName && renderPdfFileNames()}
                  </div>
                </div>
              </Paper>
            </div>
            <div className="dropzone-wrapper" style={{ width: 230 }}>
              <p className="upload-header-text">
                Please upload the proposal(s)
              </p>
              <Paper
                style={{
                  borderRadius: 10,
                }}
                elevation={3}
              >
                <div
                  className={`proposal-dropzone-container ${
                    proposalIsDragActive && proposalIsDragAccept
                      ? "active-dropzone"
                      : ""
                  }`}
                  {...proposalGetRootProps()}
                >
                  <input {...proposalGetInputProps()} />
                  <div className="proposal-dropzone-sub-container">
                    <img
                      src={UploadFileImage}
                      alt="upload-file"
                      style={{ width: 30, height: 30 }}
                    />
                    <em style={{ marginBottom: 30 }}>
                      (Only *.pdf files will be accepted, max limit: 4)
                    </em>
                    {!!invalidProposalFileError && (
                      <p className="error-text">
                        <img
                          src={NotAllowedIcon}
                          alt="not-allowed-icon"
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        &nbsp;
                        {invalidProposalFileError}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  {!!acceptedProposalFiles.length && renderProposalFileNames()}
                </div>
              </Paper>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomInputBox
              inputValue={inputValue}
              setInputValue={setInputValue}
              label="Input prompt"
            />
            {renderSubmitButton()}
          </div>
        </div>
        <div className="section-divider" />
        <div style={{ width: "100%", height: "93%" }}>
          <p className="label">Result</p>
          {isLoading ? (
            <div className="loading-container">
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                Do not close the browser. Please wait...
                <Timer />
              </p>
              <TailSpin
                visible={true}
                height="40"
                width="40"
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <>
              {!!result?.length && !isLoading && (
                // <p className="download-report-btn" onClick={handleDownload}>
                //   <SiMicrosoftexcel size={20} /> Download report
                // </p>
                <a
                  className="download-report-btn"
                  href={require("../../assets/report.xlsx")}
                  download="report"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiMicrosoftexcel size={20} /> Download Report
                </a>
              )}
              <CustomInputBox
                inputValue={result}
                setInputValue={setResult}
                containerStyles={{
                  width: "90%",
                  marginLeft: 6,
                  height: 500,
                }}
                textFieldStyles={{}}
                rows={18}
                label="Result"
                placeholder=""
                disabled={!result}
                showCopyButton
              />
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RFPBot;
