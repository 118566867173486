import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

import Footer from "../../components/footer/Footer";
import { authenticate } from "../../network/authentication";

import "./loginScreen-styles.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginScreen = () => {
  const navigation = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleLoginClick = async () => {
    setIsLoading(true);
    const apiResponse = await authenticate({
      username,
      password,
      token: captcha,
    });
    setIsLoading(false);
    if (apiResponse === "Login Successful") {
      setSnackbarMessage("Login Successful");
      setIsSnackbarOpen(true);
      setTimeout(() => {
        navigation("/applications", {
          replace: true,
          state: "Login Successful",
        });
      }, 1000);
    } else {
      if (apiResponse === "Wrong captcha") {
        setSnackbarMessage("Invalid Captcha!");
        setIsSnackbarOpen(true);
      } else if (apiResponse === "Wrong credentials") {
        setSnackbarMessage("Wrong credentials!");
        setIsSnackbarOpen(true);
      } else {
        setSnackbarMessage(apiResponse);
        setIsSnackbarOpen(true);
      }
    }
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden" }} fluid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage !== "Login Successful" ? "error" : "success"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-dark text-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "400px" }}
          >
            <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
              <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
              <p className="text-white-50 mb-5">
                Please enter your user and password!
              </p>

              <MDBInput
                wrapperClass="mb-4 mx-5 w-100"
                labelClass="text-white"
                label="Username"
                id="formControlLg-username"
                type="text"
                size="lg"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                name="username"
              />
              <div className="password-input-container">
                <MDBInput
                  wrapperClass="mb-4 mx-5 w-100"
                  labelClass="text-white"
                  label="Password"
                  id="formControlLg-password"
                  type={isPasswordVisible ? "text" : "password"}
                  size="lg"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  name="password"
                />
                <div
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                  className="eye-icon"
                >
                  {isPasswordVisible ? <IoEyeOff /> : <IoEye />}
                </div>
              </div>

              {/* <p className="small mb-3 pb-lg-2">
                <a class="text-white-50" href="#!">
                  Forgot password?
                </a>
              </p> */}
              <ReCAPTCHA
                style={{ marginBottom: 20 }}
                sitekey="6Ld_wxIpAAAAAO8F0n4QGsjkCQSdYzlf-_BGHzeH"
                onChange={(token) => {
                  setCaptcha(token);
                }}
                data-type="image"
              />

              <MDBBtn
                onClick={handleLoginClick}
                outline
                className="mx-2 px-5"
                color="white"
                size="lg"
              >
                {isLoading ? (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Login"
                )}
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <Footer />
    </div>
  );
};

export default LoginScreen;
