import axios from "axios";

import { BASE_URL } from "../constants";

export const authenticate = async (data) => {
  const url = `${BASE_URL}captcha`;
  try {
    const response = await axios.post(url, data);
    if (response?.data?.message === "reCAPTCHA verification successful !") {
      return "Login Successful";
    } else {
      return "Login Failed";
    }
  } catch (e) {
    if (e?.response?.data?.error === "reCAPTCHA verification failed.") {
      return "Wrong captcha";
    } else if (e?.response?.data?.message === "User not Authorized") {
      return "Wrong credentials";
    } else {
      return "Server under maintenance! Please try again later.";
    }
  }
};
