import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { TiHome } from "react-icons/ti";

import UploadFileImage from "../../assets/images/upload_file.png";
import PdfIcon from "../../assets/images/pdf_icon.png";
import NotAllowedIcon from "../../assets/images/not_allowed_icon.png";
import DetailCard from "../../components/card/Card";
import Footer from "../../components/footer/Footer";
import { getSalaryDetails } from "../../network/salary-details";
import Timer from "../../components/timer/Timer";
import { splitPdfFile } from "../../utils/utilities";

import "./pdfScanner-styles.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PDFScanner = () => {
  const props = useLocation();
  const navigation = useNavigate();
  const incomingMessage = props?.state;
  const [invalidFileError, setInvalidFileError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState();

  const acceptedFileTypes = { "application/pdf": [] };

  useEffect(() => {
    document.title = "PDF Scanner";
  });

  useEffect(() => {
    if (!incomingMessage) {
      navigation("/", { replace: true, state: "Redirected" });
    }
  }, []);

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    setUploadedFileName("");
    setAcceptedFiles(acceptedFiles?.length ? acceptedFiles : []);
    setInvalidFileError(rejectedFiles?.length ? "File not supported" : "");
    if (acceptedFiles?.length) {
      if (acceptedFiles.length > 1) {
        fetchSalaryDetails(acceptedFiles);
        setIsSnackbarOpen(true);
        let fileNames = "";
        acceptedFiles.forEach((item) => {
          fileNames = `${fileNames} | ${item.name}`;
        });
        setUploadedFileName(acceptedFiles?.length ? fileNames : "");
      } else {
        const chunks = await splitPdfFile(acceptedFiles[0], 10);
        if (chunks.length > 1) {
          fetchSalaryDetails(acceptedFiles, chunks);
        } else {
          fetchSalaryDetails(acceptedFiles);
        }
        setIsSnackbarOpen(true);
        setUploadedFileName(
          acceptedFiles?.length ? acceptedFiles?.[0]?.name : ""
        );
      }
    }
  };

  const fetchSalaryDetails = async (uploadedFiles, chunks = []) => {
    setIsLoading(true);
    setListData([]);
    const apiData = await getSalaryDetails(
      uploadedFiles,
      uploadedFiles.length > 1,
      chunks
    );
    if (Array.isArray(apiData)) {
      setListData(apiData);
    } else {
      setErrorMessage(apiData);
    }
    setIsLoading(false);
  };

  const { getInputProps, getRootProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      accept: acceptedFileTypes,
    });

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const renderPdfFileNames = () => {
    return (
      <p className="message">
        <img
          src={PdfIcon}
          alt="pdf-icon"
          style={{ width: 24, height: 24, marginRight: 10 }}
        />{" "}
        {acceptedFiles.length > 1
          ? `${acceptedFiles.length} files uploaded`
          : acceptedFiles[0].name}
      </p>
    );
  };

  return (
    <div className="pdf-scanner-container">
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          File uploaded successfully!
        </Alert>
      </Snackbar>
      <div
        style={{
          position: "absolute",
          left: 10,
          top: 10,
          color: "white",
        }}
      >
        <TiHome
          className="home-icon"
          onClick={() => {
            navigation(-1);
          }}
          size={40}
          color="white"
        />
        <p style={{ fontSize: 12, textAlign: "center" }}>Home</p>
      </div>
      <div className="dropzone-wrapper">
        {!uploadedFileName && !isLoading && (
          <p className="upload-header-text">Please upload the bank statement</p>
        )}
        <Paper
          style={{
            borderRadius: 10,
            marginTop: 10,
          }}
          elevation={3}
        >
          <div
            className={`dropzone-container ${
              isDragActive && isDragAccept ? "active-dropzone" : ""
            }`}
            style={isLoading ? { height: "12rem" } : {}}
            {...getRootProps()}
          >
            <input {...getInputProps()} multiple />
            <div className="dropzone-sub-container">
              <img
                src={UploadFileImage}
                alt="upload-file"
                style={{ width: 30, height: 30 }}
              />
              <em>(Only *.pdf files will be accepted)</em>
              {invalidFileError && (
                <p className="error-text">
                  <img
                    src={NotAllowedIcon}
                    alt="not-allowed-icon"
                    style={{ width: 24, height: 24, marginRight: 10 }}
                  />{" "}
                  {invalidFileError}
                </p>
              )}
              {isLoading && (
                <div style={{ width: 300 }}>
                  <p style={{ fontSize: 15 }}>
                    Don't close the browser, please wait <Timer />
                  </p>
                  <LinearProgress
                    style={{ marginTop: 0 }}
                    sx={{ width: 300 }}
                  />
                </div>
              )}
              {uploadedFileName && renderPdfFileNames()}
            </div>
          </div>
        </Paper>
      </div>
      <div style={{ width: "50%" }}>
        <Grid
          style={
            listData?.length > 6
              ? {
                  overflowY: "scroll",
                  justifyContent: "center",
                  height: "70%",
                }
              : {
                  justifyContent: "center",
                }
          }
          marginTop={2}
          container
          spacing={3}
        >
          {!invalidFileError && !isLoading && listData?.length ? (
            listData.map((item, index) => {
              const { date, salary, bank } = item;
              return (
                <DetailCard
                  key={item.toString()}
                  date={date}
                  amount={salary}
                  bank={bank}
                />
              );
            })
          ) : uploadedFileName && !isLoading ? (
            <p style={{ color: "white" }}>
              {errorMessage?.length && !listData?.length
                ? errorMessage
                : "No details found!"}
            </p>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default PDFScanner;
