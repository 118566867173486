import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import LoginScreen from "./screens/login/LoginScreen";
import Applications from "./screens/applications/Applications";
import PDFScanner from "./screens/pdf-scanner/PDFScanner";
import PDFScannerProcessor from "./screens/pdf-scanner/PdfScannerProcessor";

import RFPBot from "./screens/rfp-bot/RFPBot";
import RecruitmentAnalyzer from "./screens/recruitment-doc-analyzer/RecruitmentAnalyzer";
import HealthcareComplianceChecker from "./screens/healthcare-compliance-checker/HealthcareComplianceChecker";
import MedicalLiteratureSummariser from "./screens/medical-literature-summariser/MedicalLiteratureSummariser";
import FinancialReportAnalyzer from "./screens/financial-report-analyzer/FinancialReportAnalyzer";
import MarketingDocAnalyzer from "./screens/marketing-doc-analyzer/MarketingDocAnalyzer";
import DataCenterComplianceAnalyzer from "./screens/date-center-compliance-analyzer/DataCenterComplianceAnalyzer";
import GovtPolicyComplianceAnalyzer from "./screens/govt-policy-compliance-analyzer/GovtPolicyComplianceAnalyzer";

const hideLogs = () => {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
};

const App = () => {
  useEffect(() => {
    // hideLogs();
  }, []);

  return (
    <Routes>
      <Route element={<LoginScreen />} path="/" />
      <Route element={<Applications />} path="/applications" />
      <Route element={<PDFScanner />} path="/applications/pdf-scanner" />
      <Route element={<PDFScannerProcessor />}  path="/applications/pdf-Scannerprocessor" />

      <Route
        element={<RFPBot />}
        path="/applications/procurement-application"
      />
      <Route
        element={<RecruitmentAnalyzer />}
        path="/applications/recruitment-assistant"
      />
      <Route
        element={<HealthcareComplianceChecker />}
        path="/applications/healthcare-compliance-checker"
      />
      <Route
        element={<MedicalLiteratureSummariser />}
        path="/applications/medical-literature-summariser"
      />
      <Route
        element={<FinancialReportAnalyzer />}
        path="/applications/financial-report-analyzer"
      />
      <Route
        element={<MarketingDocAnalyzer />}
        path="/applications/marketing-analysis"
      />
      <Route
        element={<DataCenterComplianceAnalyzer />}
        path="/applications/data-center-compliance-analyzer"
      />
      <Route
        element={<GovtPolicyComplianceAnalyzer />}
        path="/applications/govt-policy-compliance-analyzer"
      />
    </Routes>
  );
};

export default App;
