import * as React from "react";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";
import { MdContentCopy } from "react-icons/md";

import "./customInputBox-styles.css";

const CustomInputBox = (props) => {
  const {
    inputValue,
    setInputValue,
    containerStyles = {},
    textFieldStyles = {},
    rows = 2,
    label,
    placeholder = "Write here..",
    disabled = false,
    showCopyButton = false,
  } = props;

  const handleCopyButton = () => {
    navigator.clipboard.writeText(inputValue);
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "4px 8px",
        display: "flex",
        alignItems: "end",
        flexDirection: "column",
        width: 400,
        height: 90,
        borderRadius: 2,
        ...containerStyles,
      }}
    >
      {showCopyButton && (
        <span
          className="copy-button"
          style={{
            cursor: "pointer",
            backgroundColor: "whitesmoke",
            padding: "4px 10px",
            borderRadius: 20,
            marginBottom: 4,
          }}
          onClick={handleCopyButton}
        >
          <MdContentCopy style={{ marginRight: 6 }} />
          Copy to clipboard
        </span>
      )}
      <TextField
        id="filled-multiline-static"
        label={label}
        multiline
        rows={rows}
        placeholder={placeholder}
        variant="outlined"
        sx={{ flex: 1, width: "100%", borderRadius: 2, ...textFieldStyles }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        disabled={disabled}
      />
    </Paper>
  );
};

export default CustomInputBox;
