import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import "./applicationCard-styles.css";

const ApplicationCard = (props) => {
  const { handleClick, label, image } = props;
  return (
    <div className="application-card-container">
      <Card
        onClick={handleClick}
        sx={{ width: 330, borderRadius: 4, maxWidth: 330 }}
      >
        <CardActionArea>
          {image}
          <CardContent>
            <Typography
              gutterBottom
              style={{ fontSize: "120%" }}
              component="div"
            >
              {label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default ApplicationCard;
