import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { TailSpin } from "react-loader-spinner";
import { TiHome } from "react-icons/ti";

import UploadFileImage from "../../assets/images/upload_file.png";
import PdfIcon from "../../assets/images/pdf_icon.png";
import NotAllowedIcon from "../../assets/images/not_allowed_icon.png";
import Footer from "../../components/footer/Footer";
import CustomInputBox from "../../components/custom-input-box/CustomInputBox";
import Timer from "../../components/timer/Timer";
import { complianceChecker } from "../../network/complianceChecker";

import "./govtPolicyComplianceAnalyzer-styles.scss";
// var XLSX = require("xlsx");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GovtPolicyComplianceAnalyzer = () => {
  const navigation = useNavigate();
  const [invalidGovtPolicyCompFileError, setInvalidGovtPolicyCompFileError] =
    useState("");
  const [invalidGovtPolicyPropFileError, setInvalidGovtPolicyPropFileError] =
    useState("");
  const [uploadedGovtPolicyPropFileName, setUploadedGovtPolicyPropFileName] =
    useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [acceptedGovtPolicyCompFiles, setAcceptedGovtPolicyCompFiles] =
    useState([]);
  const [acceptedGovtPolicyPropFiles, setAcceptedGovtPolicyPropFiles] =
    useState([]);
  const [flag, setFlag] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    document.title = "Government Policy Compliance Analyzer";
  }, []);

  const ACCEPTED_FILE_TYPES = { "application/pdf": [] };

  const onGovtPolicyCompFileDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      console.log("Govt Policy Comp File accepted", acceptedFiles);
      console.log("Govt Policy Comp File rejected", rejectedFiles);
      setUploadedGovtPolicyPropFileName("");
      setResult("");
      setFlag(false);
      setAcceptedGovtPolicyCompFiles(
        acceptedFiles?.length ? acceptedFiles : []
      );
      setInvalidGovtPolicyCompFileError(
        rejectedFiles?.length ? "File not supported" : ""
      );
      if (acceptedFiles?.length) {
        setIsSnackbarOpen(true);
        setUploadedGovtPolicyPropFileName(
          acceptedFiles?.length ? acceptedFiles?.[0]?.name : ""
        );
      }
    },
    []
  );

  const sendFilesForMatching = async (
    govtPolicyCompFile,
    govtPolicyPropFiles
  ) => {
    setIsLoading(true);
    const response = await complianceChecker(
      govtPolicyCompFile,
      govtPolicyPropFiles,
      inputValue,
      "governmentPolicy"
    );
    setResult(response);
    setFlag(true);
    setIsLoading(false);
  };

  const handleSubmitBtnClick = async () => {
    await sendFilesForMatching(
      acceptedGovtPolicyCompFiles,
      acceptedGovtPolicyPropFiles
    );
  };

  const onGovtPolicyPropFileDrop = async (acceptedFiles, rejectedFiles) => {
    setSnackbarMsg("");
    console.log("DC Prop file accepted", acceptedFiles);
    console.log("DC Prop file rejected", rejectedFiles);
    setResult("");
    setFlag(false);
    if (acceptedGovtPolicyPropFiles?.length + acceptedFiles?.length > 4) {
      const tempFiles = [...acceptedGovtPolicyPropFiles, ...acceptedFiles];
      tempFiles.length = 4;
      setAcceptedGovtPolicyPropFiles(tempFiles);
      setSnackbarMsg("Maximum 4 files are allowed");
    } else {
      if (acceptedGovtPolicyPropFiles?.length) {
        setAcceptedGovtPolicyPropFiles([
          ...acceptedGovtPolicyPropFiles,
          ...acceptedFiles,
        ]);
      } else {
        setAcceptedGovtPolicyPropFiles(acceptedFiles);
      }
      setInvalidGovtPolicyPropFileError(
        rejectedFiles?.length ? "File not supported" : ""
      );
    }
    if (acceptedFiles?.length) {
      setIsSnackbarOpen(true);
    }
  };

  const { getInputProps, getRootProps, isDragActive, isDragAccept } =
    useDropzone({
      multiple: false,
      onDrop: onGovtPolicyCompFileDrop,
      accept: ACCEPTED_FILE_TYPES,
    });

  const {
    getInputProps: govtPolicyPropGetInputProps,
    getRootProps: govtPolicyPropGetRootProps,
    isDragActive: govtPolicyPropIsDragActive,
    isDragAccept: govtPolicyPropIsDragAccept,
  } = useDropzone({
    multiple: true,
    onDrop: onGovtPolicyPropFileDrop,
    accept: ACCEPTED_FILE_TYPES,
  });

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const renderPdfFileNames = () => {
    return (
      <p className="message">
        <img
          src={PdfIcon}
          alt="pdf-icon"
          style={{ width: 24, height: 24, marginRight: 10 }}
        />
        &nbsp;
        {acceptedGovtPolicyCompFiles.length > 1
          ? `${acceptedGovtPolicyCompFiles.length} files uploaded`
          : acceptedGovtPolicyCompFiles[0].name.length > 20
          ? `${acceptedGovtPolicyCompFiles[0].name.slice(0, 20)}...`
          : `${acceptedGovtPolicyCompFiles[0].name}`}
      </p>
    );
  };

  // const handleDownload = () => {
  //   const matchPercent = Number(result?.[0]?.match_percent).toFixed(2);
  //   const resWidth = result?.[0]?.dimensions?.width;
  //   const resHeight = result?.[0]?.dimensions?.height;
  //   const resColor = result?.[0]?.color;
  //   const data = [
  //     ["S. No.", "Match Percent", "Dimensions", "Color"],
  //     [1, matchPercent, `${resWidth} x ${resHeight}`, resColor],
  //   ];

  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.aoa_to_sheet(data);

  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   const blob = XLSX.write(wb, {
  //     bookType: "xlsx",
  //     type: "array",
  //     mimeType:
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });

  //   saveAs(
  //     new Blob([blob], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     }),
  //     "report.xlsx"
  //   );
  // };

  const handleDeselectFileClick = (fileIndex) => {
    const tempReportFiles = [...acceptedGovtPolicyPropFiles];
    tempReportFiles.splice(fileIndex, 1);
    setAcceptedGovtPolicyPropFiles(tempReportFiles);
  };

  const renderGovtPolicyPropFileNames = () => {
    return acceptedGovtPolicyPropFiles.map((fileItem, index) => {
      const { name } = fileItem;
      const displayName = name.slice(0, -4);
      return (
        <Chip
          variant="filled"
          label={
            displayName?.length > 15
              ? `${displayName.slice(0, 15)}..`
              : displayName
          }
          onDelete={() => {
            handleDeselectFileClick(index);
          }}
          style={{ margin: 4 }}
          avatar={
            <img
              src={PdfIcon}
              alt="pdf-icon"
              style={{ width: 24, height: 24 }}
            />
          }
        />
      );
    });
  };

  const renderSubmitButton = () => {
    return (
      <div
        onClick={
          !!uploadedGovtPolicyPropFileName &&
          !!acceptedGovtPolicyPropFiles?.length &&
          inputValue &&
          !isLoading
            ? handleSubmitBtnClick
            : () => {}
        }
        style={{ cursor: "pointer" }}
        className="trail_btn"
      >
        <p
          style={{
            background:
              !!acceptedGovtPolicyPropFiles?.length &&
              !!uploadedGovtPolicyPropFileName &&
              !!inputValue
                ? ""
                : "gray",
            border:
              !!acceptedGovtPolicyPropFiles?.length &&
              !!uploadedGovtPolicyPropFileName &&
              !!inputValue
                ? "1px solid white"
                : "none",
          }}
        >
          Submit
        </p>
      </div>
    );
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMsg ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMsg ? snackbarMsg : "File uploaded successfully!"}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div className="govt-policy-report-analyzer-container">
      {renderSnackbar()}
      <div
        style={{
          position: "absolute",
          left: 10,
          top: 10,
          color: "white",
        }}
      >
        <TiHome
          className="home-icon"
          onClick={() => {
            navigation(-1);
          }}
          size={40}
          color="white"
        />
        <p style={{ fontSize: 12, textAlign: "center" }}>Home</p>
      </div>
      <div className="sub-container">
        <div className="inputs-section">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="dropzone-wrapper">
              <p className="upload-header-text">
                Upload the government policy document pdf
              </p>
              <Paper
                style={{
                  borderRadius: 10,
                }}
                elevation={3}
              >
                <div
                  className={`dropzone-container ${
                    isDragActive && isDragAccept ? "active-dropzone" : ""
                  }`}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="dropzone-sub-container">
                    <img
                      src={UploadFileImage}
                      alt="upload-file"
                      style={{ width: 30, height: 30 }}
                    />
                    <em>(Only *.pdf files will be accepted)</em>
                    {!!invalidGovtPolicyCompFileError && (
                      <p className="error-text" style={{ marginBottom: 10 }}>
                        <img
                          src={NotAllowedIcon}
                          alt="not-allowed-icon"
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        &nbsp;
                        {invalidGovtPolicyCompFileError}
                      </p>
                    )}
                    {!!uploadedGovtPolicyPropFileName && renderPdfFileNames()}
                  </div>
                </div>
              </Paper>
            </div>
            <div className="dropzone-wrapper" style={{ width: 350 }}>
              <p className="upload-header-text">
                Please upload the policy proposal(s)
              </p>
              <Paper
                style={{
                  borderRadius: 10,
                }}
                elevation={3}
              >
                <div
                  className={`proposal-dropzone-container ${
                    govtPolicyPropIsDragActive && govtPolicyPropIsDragAccept
                      ? "active-dropzone"
                      : ""
                  }`}
                  {...govtPolicyPropGetRootProps()}
                >
                  <input {...govtPolicyPropGetInputProps()} />
                  <div className="proposal-dropzone-sub-container">
                    <img
                      src={UploadFileImage}
                      alt="upload-file"
                      style={{ width: 30, height: 30 }}
                    />
                    <em style={{ marginBottom: 30 }}>
                      (Only *.pdf files will be accepted, max limit: 4)
                    </em>
                    {!!invalidGovtPolicyPropFileError && (
                      <p className="error-text">
                        <img
                          src={NotAllowedIcon}
                          alt="not-allowed-icon"
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        &nbsp;
                        {invalidGovtPolicyPropFileError}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  {!!acceptedGovtPolicyPropFiles.length &&
                    renderGovtPolicyPropFileNames()}
                </div>
              </Paper>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomInputBox
              inputValue={inputValue}
              setInputValue={setInputValue}
              label="Input prompt"
            />
            {renderSubmitButton()}
          </div>
        </div>
        <div className="section-divider" />
        <div style={{ width: "100%", height: "93%" }}>
          <p className="label">Result</p>
          {isLoading ? (
            <div className="loading-container">
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                Do not close the browser. Please wait...
                <Timer />
              </p>
              <TailSpin
                visible={true}
                height="40"
                width="40"
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <CustomInputBox
              inputValue={result}
              setInputValue={setResult}
              containerStyles={{
                width: "90%",
                marginLeft: 6,
                height: 500,
              }}
              textFieldStyles={{}}
              rows={18}
              label="Result"
              placeholder=""
              disabled={!result}
              showCopyButton
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GovtPolicyComplianceAnalyzer;
