import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";

import PdfIcon from "../../assets/images/pdf_icon.png";
import ClaudeAIImage from "../../assets/images/claudeAI.png";
import HealthcareImage from "../../assets/images/healthcare_image.jpg";
import MedicalLiterature from "../../assets/images/medical_literature.jpg";
import FinancialReportAnalyzer from "../../assets/images/financial_report_analyzer.jpg";
import MarketingDocAnalyzer from "../../assets/images/marketing_doc_analyzer.jpg";
import DataCenterComplianceAnalyzer from "../../assets/images/data-center-compliance-analyzer.jpg";
import GovtPolicyComplianceAnalyzer from "../../assets/images/govt_policy_compliance_analyzer.jpg";
import Footer from "../../components/footer/Footer";
import ApplicationCard from "../../components/application-card/ApplicationCard";

import "./applications-styles.scss";

const Applications = (props) => {
  const navigation = useNavigate();

  useEffect(() => {
    document.title = "Tarang POC Portal";
  });

  const onLogoutBtnClick = () => {
    navigation("/", { replace: true });
  };

  return (
    <div className="applications-container">
      <p style={{ fontSize: 32, fontWeight: 500, marginTop: 10 }}>
        Applications
      </p>
      <MDBBtn
        onClick={onLogoutBtnClick}
        outline
        className="mx-2 px-5 logout-button"
        color="white"
        size="md"
      >
        Logout
      </MDBBtn>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "90%",
          rowGap: 28,
          padding: 10,
          marginBottom: 70,
          overflowY: "scroll",
        }}
        className="app-container"
      >

<ApplicationCard
          handleClick={() => {
            navigation("/applications/pdf-Scannerprocessor", {
              state: "Login Successful",
            });
          }}
          label="Bank Statement Processor"
          image={
            <img
              src={PdfIcon}
              alt="pdf-icon"
              style={{
                backgroundColor: "royalblue",
                objectFit: "contain",
                width: "100%",
                height: 180,
              }}
            />
          }
        />


        <ApplicationCard
          handleClick={() => {
            navigation("/applications/procurement-application");
          }}
          label="Tarang Procurement Application"
          image={
            <img
              src={ClaudeAIImage}
              alt="procurement-application"
              style={{ objectFit: "fill", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/recruitment-assistant");
          }}
          label="Recruitment Assistant"
          image={
            <img
              src={ClaudeAIImage}
              alt="recruitment assistant"
              style={{ objectFit: "fill", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/healthcare-compliance-checker");
          }}
          label="Healthcare Compliance Checker"
          image={
            <img
              src={HealthcareImage}
              alt="healthcare-compliance-checker"
              style={{ objectFit: "fill", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/medical-literature-summariser");
          }}
          label="Medical Literature Summariser"
          image={
            <img
              src={MedicalLiterature}
              alt="medical-literature-summariser"
              style={{ objectFit: "cover", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/financial-report-analyzer");
          }}
          label="Financial Report Analyzer"
          image={
            <img
              src={FinancialReportAnalyzer}
              alt="financial-report-analyzer"
              style={{ objectFit: "cover", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/marketing-analysis");
          }}
          label="Marketing Analysis"
          image={
            <img
              src={MarketingDocAnalyzer}
              alt="marketing-analysis"
              style={{ objectFit: "cover", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/data-center-compliance-analyzer");
          }}
          label="Data Center Compliance Analyzer"
          image={
            <img
              src={DataCenterComplianceAnalyzer}
              alt="data-center-compliance-analyzer"
              style={{ objectFit: "cover", width: "100%", height: 180 }}
            />
          }
        />
        <ApplicationCard
          handleClick={() => {
            navigation("/applications/govt-policy-compliance-analyzer");
          }}
          label="Government Policy Compliance Analyzer"
          image={
            <img
              src={GovtPolicyComplianceAnalyzer}
              alt="govt-policy-compliance-analyzer"
              style={{ objectFit: "cover", width: "100%", height: 180 }}
            />
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Applications;
