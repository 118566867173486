import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { TailSpin } from "react-loader-spinner";
import { TiHome } from "react-icons/ti";

import UploadFileImage from "../../assets/images/upload_file.png";
import PdfIcon from "../../assets/images/pdf_icon.png";
import NotAllowedIcon from "../../assets/images/not_allowed_icon.png";
import Footer from "../../components/footer/Footer";
import CustomInputBox from "../../components/custom-input-box/CustomInputBox";
import Timer from "../../components/timer/Timer";
import { pdfclientRequestinitiate } from "../../network/PDFClientRequest";  // You need to implement this function

import "./pdfScanner-styles.css"; // Add appropriate styling

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PdfScannerProcessor = () => {
  const navigation = useNavigate();
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    document.title = "PDF Scanner Processor";
  }, []);

  const ACCEPTED_FILE_TYPES = { "application/pdf": [] };

  const sendFilesForProcessing = async (files) => {
    setIsLoading(true);
    const response = await pdfclientRequestinitiate(files, inputValue);
    setResult(response);
    setIsLoading(false);
  };

  const handleSubmitBtnClick = async () => {
    await sendFilesForProcessing(acceptedFiles);
  };

  const onFileDrop = async (acceptedFiles, rejectedFiles) => {
    setSnackbarMsg("");
    setResult("");
    if (acceptedFiles.length > 4) {
      const tempFiles = [...acceptedFiles];
      tempFiles.length = 4;
      setAcceptedFiles(tempFiles);
      setSnackbarMsg("Maximum 4 files are allowed");
    } else {
      setAcceptedFiles(acceptedFiles);
    }
    if (acceptedFiles.length) {
      setIsSnackbarOpen(true);
    }
  };

  const {
    getInputProps,
    getRootProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    multiple: true,
    onDrop: onFileDrop,
    accept: ACCEPTED_FILE_TYPES,
  });

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleDeselectFileClick = (fileIndex) => {
    const tempFiles = [...acceptedFiles];
    tempFiles.splice(fileIndex, 1);
    setAcceptedFiles(tempFiles);
  };

  const renderFileNames = () => {
    return acceptedFiles.map((fileItem, index) => {
      const { name } = fileItem;
      const displayName = name.slice(0, -4);
      return (
        <Chip
          key={index}
          variant="filled"
          label={
            displayName.length > 15
              ? `${displayName.slice(0, 15)}..`
              : displayName
          }
          onDelete={() => {
            handleDeselectFileClick(index);
          }}
          style={{ margin: 4 }}
          avatar={
            <img
              src={PdfIcon}
              alt="pdf-icon"
              style={{ width: 24, height: 24 }}
            />
          }
        />
      );
    });
  };

  const renderSubmitButton = () => {
    return (
      <div
        onClick={
          !!acceptedFiles.length && inputValue && !isLoading
            ? handleSubmitBtnClick
            : () => {}
        }
        style={{ cursor: "pointer" }}
        className="trail_btn"
      >
        <p
          style={{
            background: !!acceptedFiles.length && !!inputValue ? "" : "gray",
            border: !!acceptedFiles.length && !!inputValue
              ? "1px solid white"
              : "none",
          }}
        >
          Submit
        </p>
      </div>
    );
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMsg ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMsg ? snackbarMsg : "File uploaded successfully!"}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div className="pdf-scanner-processor-container">
      {renderSnackbar()}
      <div
        style={{
          position: "absolute",
          left: 10,
          top: 10,
          color: "white",
        }}
      >
        <TiHome
          className="home-icon"
          onClick={() => {
            navigation(-1);
          }}
          size={40}
          color="white"
        />
        <p style={{ fontSize: 12, textAlign: "center" }}>Home</p>
      </div>
      <div className="sub-container">
        <div className="inputs-section">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="dropzone-wrapper"
              style={{ width: 320, marginTop: 6 }}
            >
              <p className="upload-header-text">
                Please upload the PDF(s)
              </p>
              <Paper
                style={{
                  borderRadius: 10,
                }}
                elevation={3}
              >
                <div
                  className={`proposal-dropzone-container ${
                    isDragActive && isDragAccept ? "active-dropzone" : ""
                  }`}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="proposal-dropzone-sub-container">
                    <img
                      src={UploadFileImage}
                      alt="upload-file"
                      style={{ width: 30, height: 30 }}
                    />
                    <em style={{ marginBottom: 30 }}>
                      (Only *.pdf files will be accepted, max limit: 4)
                    </em>
                  </div>
                </div>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  {!!acceptedFiles.length && renderFileNames()}
                </div>
              </Paper>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomInputBox
              inputValue={inputValue}
              setInputValue={setInputValue}
              label="Input prompt"
              containerStyles={{ marginTop: -22 }}
            />
            {renderSubmitButton()}
          </div>
        </div>
        <div className="section-divider" />
        <div style={{ width: "100%", height: "93%" }}>
          <p className="label">Result</p>
          {isLoading ? (
            <div className="loading-container">
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                Do not close the browser. Please wait...
                <Timer />
              </p>
              <TailSpin
                visible={true}
                height="40"
                width="40"
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <CustomInputBox
              inputValue={result}
              setInputValue={setResult}
              containerStyles={{
                width: "90%",
                marginLeft: 6,
                height: 500,
              }}
              textFieldStyles={{}}
              rows={18}
              label="Result"
              placeholder=""
              disabled={!result}
              showCopyButton
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PdfScannerProcessor;
