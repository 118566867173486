import React, { useState, useEffect } from "react";

const Timer = () => {
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsElapsed((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return (
      <span style={{ fontStyle: "italic" }}>
        {minutes}m {remainingSeconds}s
      </span>
    );
  };

  return <span>{formatTime(secondsElapsed)}</span>;
};

export default Timer;
