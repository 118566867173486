import axios from "axios";

const pdfclientRequestinitiate = async (files, promptInput, key) => {
  let url = `https://tarangtech.in/get_controller_finance_statement_Processor?prompt=${promptInput}`;

  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response?.data?.data) {
      return response?.data?.data;
    } else {
      return "";
    }
  } catch (err) {
    console.log("API Error", err);
    return "";
  }
};

export { pdfclientRequestinitiate };
